<template>
  <div class="Integration">
    <div class="Integration-banner">
      <img :src="require('@imgs/Integration/web/banner.png')" v-if="$isMobile()" />
      <img :src="require('@imgs/Integration/banner.png')" v-else>
      <div class="Integration-banner-title">
        <h3>关于我们</h3>
        <span>Integration US</span>
      </div>
    </div>
    <div class="Integration-content">
      <div class="Integration-header">
        <div class="Integration-breadcrumb text-algin-sm pading-top-10-sm">
          当前位置:首页 -> 产教融合
        </div>
      </div>
      <div class="row" style="margin-top:50px;">
        <div class="Integration-content-item col-sm-4 col-12" v-for="(item, index) in dataList" :key="index">
          <Integration-item :info="item"></Integration-item>
        </div>
        <page style="width:100%;display:flex;justify-content:center;"></page>
      </div>
    </div>
  </div>
</template>
<script>
import IntegrationItem from '../application/components/application-item'
import page from '@components/page/page'
export default {
  name: 'Integration',
  components: {
    IntegrationItem,
    page
  },
  data() {
    return {
      dataList: []
    }
  }
}
</script>
<style scoped lang="scss">
.Integration {
  position: relative;

  &-content-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }

  &-banner {
    width: 100%;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;

      h3 {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: .8;
        font-family: Microsoft YaHei;
      }

      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        font-family: Microsoft YaHei;
      }
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    min-height: 300px;
    z-index: 9;
    padding: 0 12px;
  }

  &-header {
    margin-top: 40px;
    align-items: center;
  }

  &-breadcrumb {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: right;
    font-family: Microsoft YaHei;
  }
}

.Integration-content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}</style>